.formContent {
    width: 100% !important;
    margin: 0 !important;
}

.formContent__check input {
    border: 2px solid red;
    width: auto;
    height: auto;
    margin: 10px 30px 0 0;
}

.form-check-label {
    margin-left: 20px !important;
}

/*=======  Common Classes  =======*/
.formContent__btn {
    width: auto !important;
    height: auto !important;
    line-height: 70px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    border-radius: 35px !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0 50px !important;
    background-color: var( --primary) !important;
    color: #fff !important;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s !important;
    position: relative !important;
    z-index: 2 !important;
}

@media (max-width: 1199px) {
    .formContent__btn {
        padding: 0 40px;
        font-size: 16px;
        line-height: 60px;
    }
}

@media (max-width: 575px) {
    .formContent__btn {
        padding: 0 35px;
        font-size: 14px;
        line-height: 60px;
    }
}

.formContent__btn::after {
    position: absolute;
    right: 15px;
    top: 4px;
    bottom: 4px;
    background-color: var( --bgFooter);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    content: '';
    z-index: -1;
    width: 85px;
    border-radius: 30px;
}

.formContent__btn:hover {
    color: #fff;
}

.formContent__btn:hover::after {
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
}