.modal__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.modal__close {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 10px;
    cursor: pointer;
}

.modal__close:hover {
    color: red;
}

.modal__form {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin: 20px auto 0;
    padding: 0;
}

.modal__form h3 {
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
    font-size: 30px;
}

.modal__logo {
    width: 100%;
    height: auto;
    margin-top: 20px;
    text-align: center;
}

.modal__logo img {
    width: 70%;
    height: auto;
}