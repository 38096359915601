	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/08/2020 21:55
  	*/

@font-face {
	font-family: 'Flaticon';
	src: url('../fonts/Flaticon.eot');
	src: url('../fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Flaticon.woff2') format('woff2'),
		url('../fonts/Flaticon.woff') format('woff'),
		url('../fonts/Flaticon.ttf') format('truetype'),
		url('../fonts/Flaticon.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: 'Flaticon';
		src: url('../fonts/Flaticon.svg') format('svg');
	}
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
	font-family: Flaticon;
	font-style: normal;
	line-height: 1;
}

.flaticon-graphic:before { content: "\f100"; }
.flaticon-plan:before { content: "\f101"; }
.flaticon-target:before { content: "\f102"; }
.flaticon-teamwork:before { content: "\f103"; }
.flaticon-presentation:before { content: "\f104"; }
.flaticon-money-bags:before { content: "\f105"; }
.flaticon-invest:before { content: "\f106"; }
.flaticon-connector:before { content: "\f107"; }
.flaticon-quote:before { content: "\f108"; }
.flaticon-quote-1:before { content: "\f109"; }
.flaticon-graphic-1:before { content: "\f10a"; }
.flaticon-pie-chart:before { content: "\f10b"; }
.flaticon-graph:before { content: "\f10c"; }
.flaticon-setup:before { content: "\f10d"; }
.flaticon-target-1:before { content: "\f10e"; }
.flaticon-pie-chart-1:before { content: "\f10f"; }
.flaticon-money:before { content: "\f110"; }
.flaticon-chart:before { content: "\f111"; }
.flaticon-graph-1:before { content: "\f112"; }
.flaticon-diagram:before { content: "\f113"; }
.flaticon-diamond:before { content: "\f114"; }
.flaticon-teamwork-1:before { content: "\f115"; }
.flaticon-idea:before { content: "\f116"; }
.flaticon-training:before { content: "\f117"; }
.flaticon-creative:before { content: "\f118"; }
.flaticon-fingerprint:before { content: "\f119"; }
.flaticon-shield:before { content: "\f11a"; }
.flaticon-analysis:before { content: "\f11b"; }
.flaticon-computer:before { content: "\f11c"; }
.flaticon-piggy-bank:before { content: "\f11d"; }